import { useContext, useEffect, useState } from "react";
import { EventDTO } from "../../event/dto/event.dto";
import mediaContext from "../context/media-context";
import socketContext from "../context/socket-context";
import { ChatData } from "../dto/chat-message.dto";
import { ConnectedUser } from "../dto/connected-user.dto";
import ActionBar from "./action-bar";
import { RightBar } from "./right-bar";

import ivsContext from "../context/ivs-context";
import ParticipantVideos from "./participant-video";
import { Participant } from "amazon-ivs-web-broadcast/dist/src/stage/core-api/stage-connection";
import { DeviceType, disableMedia, handleMediaToggle } from "../mediaDevices";
import { setupStrategy } from "../stageUtils";
import { StageStrategy } from "amazon-ivs-web-broadcast";

export interface VideoGalleryProps{
    me: ConnectedUser;
    users: ConnectedUser[];
    event: EventDTO;
    participants: Participant[];
    setShowStartModal: Function;
    chatMessages: ChatData[]
}

export default function VideoGallery(props: VideoGalleryProps){
    const [cameraEnabled, setCameraEnabled] = useState(false);
    const [audioEnabled, setAudioEnabled] = useState(false);
    const [shareEnabled, setShareEnabled] = useState(false);
    const [communityEnabled, setCommunityEnabled] = useState(false);

    const [videoParticipants, setVideoParticipants] = useState<Participant[]>([]);
    const [isMeSharing, setIsMeSharing] = useState<boolean | undefined>(undefined);

    const ivsClient = useContext(ivsContext);
    const mediaStream = useContext(mediaContext);
    const socket = useContext(socketContext);

    const disableShare = () => {
        setShareEnabled(false);
        setIsMeSharing(undefined);
        setShowScreenSharePanel(EnumShowScreenSharePanel.None);
    }

    const toggleShare = () => {
        if (shareEnabled){
            mediaStream.stopShareScreen();
            setIsMeSharing(undefined);
            setShareEnabled(false);
            setShowScreenSharePanel(EnumShowScreenSharePanel.None);

        } else {
            setIsMeSharing(true);
            setShareEnabled(true);

            if ( mediaStream.isStartShareScreenWithVideoElement()) {
                setShowScreenSharePanel(EnumShowScreenSharePanel.Video);
                const screenShareVideo = document.getElementById('screen-share-video') as HTMLVideoElement;
                mediaStream.startShareScreen(screenShareVideo)
                .then(() => {
                    console.log("Screen shared")
                    })
                  .catch((error: any) => {
                    console.log(error)
                  })
              } else {
                setShowScreenSharePanel(EnumShowScreenSharePanel.Canvas);

                const screenShareCanvas = document.getElementById('screen-share-canvas') as HTMLCanvasElement;
                if (screenShareCanvas){
                    mediaStream.startShareScreen(screenShareCanvas)
                    .then(() => {
                          console.log("Screen shared")
                      })
                    .catch((error: any) => {
                      console.log(error)
                    })
                }
                }

              
            

        }
    }

    const updateVideoScreenShare = (payload: any) => {
        if (payload.state === 'Active') {
            setIsMeSharing(false);
            setShareEnabled(true);
            setShowScreenSharePanel(EnumShowScreenSharePanel.Canvas);
            const screenShareCanvas = document.getElementById('screen-share-canvas') as HTMLCanvasElement;
            if (screenShareCanvas){
                mediaStream.startShareView(
                    screenShareCanvas,
                    payload.userId
                  )
            }
          } else if (payload.state === 'Inactive') {
            setIsMeSharing(undefined);
            setShareEnabled(false);
            setShowScreenSharePanel(EnumShowScreenSharePanel.None);
            mediaStream.stopShareView()
          }
    }


    const toggleCamera = () => {
        handleMediaToggle(DeviceType.CAMERA, ivsClient, setCameraEnabled);
    }

    const toggleAudio = () => {
        handleMediaToggle(DeviceType.MIC, ivsClient, setAudioEnabled);

    }

    const updateVideos = () => {
        console.log("Updating videos")
        //const zoomUserList = zoomClient.getAllUser();
        /*const videoParticipants : Participant[] | undefined = zoomUserList?.filter((user) => {
            if (user.bVideoOn && props.users.find( (socketUser) =>  user.displayName == socketUser.userName)?.role == 2) return true;
            if (user.bVideoOn && props.me.role == 2) { return true; }
            if (user.bVideoOn && props.me.role != 2 && props.me.userName == user.displayName) return true; 
            if (user.bVideoOn && props.users.find( (socketUser) => user.displayName == socketUser.userName)?.visible) return true;
            
            
            console.log("Navigating into the filter false :/");
            return false;
        });*/

        if (videoParticipants){
            setVideoParticipants(videoParticipants);
        }

    };

    useEffect( () => {
        const updateMe = (user: ConnectedUser) => {
        
            if (user.invited == false && user.role != 2){
            

                

         
            }
        }


        socket.on("user-updated",  updateVideos);
        socket.on("me", updateMe); 

        

        updateVideos();

                    
        

        return () => {
            /*zoomClient.off("peer-video-state-change", updateVideos);
            zoomClient.off('user-added', updateVideos);
            zoomClient.off('user-removed', updateVideos);
            zoomClient.off('user-updated', updateVideos);*/
            socket.off("user-updated",  updateVideos);
            socket.off("me", updateMe); 
        }
    }, [props.users])

    enum EnumShowScreenSharePanel{
        None,
        Canvas,
        Video
    }
    const [showScreenSharePanel, setShowScreenSharePanel] = useState<EnumShowScreenSharePanel>(EnumShowScreenSharePanel.None);

    
    return <div style={{display:'flex', flex: 6, flexDirection: 'column'}}>
          <div className="video-grid-container">
            <div className={`grid grid-${props.participants.length}`}>
              <ParticipantVideos
                users={props.users}
                me={props.me}
                isInitializeComplete={true}
                participants={props.participants}
              />
            </div>
        </div>

        { <ActionBar className={"studio-desktop-action-bar"} showShare={isMeSharing == undefined || isMeSharing }  shareEnabled={shareEnabled} chatEnabled={props.event.chatEnabled} audioEnabled={audioEnabled} cameraEnabled={cameraEnabled} event={props.event} me={props.me} setShowStartModal={props.setShowStartModal} socket={socket} toggleAudio={toggleAudio} toggleCamera={toggleCamera} toggleShare={toggleShare} users={props.users}/>}

        <div className="studio-mobile-bottom-bar">
                    <div style={{flex: 5, display: "flex"}}>
                        <RightBar className="studio-mobile-right-bar" forceShowCommunityWidget={communityEnabled} users={props.users} me={props.me!} chatMessages={props.chatMessages} chatEnabled={props.event ? props.event.chatEnabled : false} socket={socket} />
                    </div>
                    <div>
                        { <ActionBar direction="column" showShare={isMeSharing || shareEnabled } toggleCommunity = { () => { setCommunityEnabled(!communityEnabled) }}className={"studio-mobile-action-bar"} chatEnabled={props.event.chatEnabled} shareEnabled={shareEnabled} audioEnabled={audioEnabled} cameraEnabled={cameraEnabled} event={props.event} me={props.me} setShowStartModal={props.setShowStartModal} socket={socket} toggleAudio={toggleAudio} toggleCamera={toggleCamera} toggleShare={toggleShare} users={props.users}/>}
                    </div>
                </div>

    </div>  
}